@use '@carbon/react' with (
  $use-flexbox-grid: true
);

@use '@carbon/colors';

.primary {
  color: colors.$blue-80;
}

@media screen and (min-width: 1024px) {
  #main-content {
    margin-left: 16rem;
  }
}


// forms
/* input {
  --cds-layout-size-height-local: clamp(max(var(--cds-layout-size-height-min), var(--cds-layout-size-height-sm)), var(--cds-layout-size-height, var(--cds-layout-size-height-md)), min(var(--cds-layout-size-height-max), var(--cds-layout-size-height-lg)));
  --cds-layout-density-padding-inline-local: clamp(var(--cds-layout-density-padding-inline-min), var(--cds-layout-density-padding-inline, var(--cds-layout-density-padding-inline-normal)), var(--cds-layout-density-padding-inline-max));
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  padding: 0 var(--cds-layout-density-padding-inline-local);
  border: none;
  background-color: var(--cds-field);
  block-size: var(--cds-layout-size-height-local);
  border-block-end: 1px solid var(--cds-border-strong);
  color: var(--cds-text-primary, #161616);
  font-family: inherit;
  inline-size: 100%;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}


.cds--label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: inline-block;
  color: var(--cds-text-secondary, #525252);
  font-weight: 400;
  line-height: 1rem;
  margin-block-end: 0.5rem;
  vertical-align: baseline;
}
 */
input:disabled, .cds--select-input:disabled, .cds--text-area:disabled {
  outline: none!important;
  outline-offset: none!important;
  background: none!important;
  border-block-end: 1px solid var(--cds-border-strong)!important;
  color: var(--cds-text-primary, #161616)!important;
  cursor: text!important;
  -webkit-text-fill-color:  var(--cds-text-primary, #161616)!important;
}

.cds--label--disabled, .cds--form__helper-text--disabled {
  color: var(--cds-text-primary, #161616)!important;
}

.cds--file-container {
  display: none!important;
}

.cds--file__selected-file {
  padding: 15px!important;
  display: grid!important;
  align-items: center!important;
  background-color: var(--cds-layer)!important;
  gap: 0.75rem 0!important;
  grid-auto-rows: auto!important;
  grid-template-columns: 1fr auto!important;
  margin-block-end: 0.5rem!important;
  max-inline-size: 20rem!important;
  min-block-size: 3rem!important;
  word-break: break-word!important;
  margin-bottom: 10px!important;
}


// https://www.npmjs.com/package/@carbon/styles

// or per component
//@use '@carbon/styles/scss/reset';
//@use '@carbon/styles/scss/components/accordion';
//@use '@carbon/styles/scss/components/button';
//@use '@carbon/styles/scss/components/checkbox';

/* 

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
 */